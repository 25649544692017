import { createSlice } from '@reduxjs/toolkit';

import { ICurrency } from '../../constants/currencies';
import { ENGLISH } from '../../constants';

export interface GlobalState {
  status: 'idle' | 'loading' | 'failed';

  toggleLanguage: boolean;
  language: {
    languageTag: 'en' | string;
    isRTL: boolean;
  };

  allCurrencies: ICurrency[];
  selectedCurrency?: ICurrency;

  leoncoAdminView: boolean;
}

const initialState: GlobalState = {
  status: 'idle',

  toggleLanguage: false,
  language: {
    languageTag: ENGLISH,
    isRTL: false,
  },

  allCurrencies: [],
  selectedCurrency: undefined,

  leoncoAdminView: false,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setLanguage: (state, { payload = ENGLISH }) => {
      state.language.languageTag = payload;
      state.language.isRTL = false;
    },
    switchLanguage: (state, action) => {
      state.toggleLanguage = action.payload;
    },
    setLeoncoAdminView: (state, action) => {
      state.leoncoAdminView = action.payload;
    },
    setSelectedCurrency: (state, action) => {
      state.selectedCurrency = action.payload;
    },
    saveCurrencies: (state, action) => {
      state.allCurrencies = action.payload;
    },
  },
});

export const {
  setLanguage,
  switchLanguage,
  setLeoncoAdminView,
  setSelectedCurrency,
  saveCurrencies,
} = globalSlice.actions;

export default globalSlice.reducer;
