import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CORPORATE_ADD_CREDIT,
  CORPORATE_CREDIT_REQUEST,
  CREDIT_REQUEST,
  FETCH_COMPANY,
  TOP_UP_COMPANY,
} from '../../constants/routes';
import { IQuery } from '../../types/default';
import {
  Company,
  ICompanyArgs,
  ICompanyCreditResponse,
  ICompanyGrantTopUp,
  ICompanyTopUp,
  ICreateCompany,
  IDeactivateCompany,
  IUpdateCompany,
} from '../../types/company';
import { staggeredBaseQuery } from '../base-query';

export const companyApi = createApi({
  reducerPath: 'company',
  baseQuery: staggeredBaseQuery,
  endpoints: (builder) => ({
    fetchCompany: builder.query<Company, ICompanyArgs>({
      query: ({ companyUUID, params }) => ({
        url: `${FETCH_COMPANY}/${companyUUID}${Object.entries(params).reduce(
          (acc, key, index) => {
            if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
            return `${acc}&${key[0]}=${key[1]}`;
          },
          '',
        )}`,
        method: 'GET',
      }),
    }),
    fetchAllCompanies: builder.query<Company[], IQuery>({
      query: ({ ...params }) => ({
        url: `${FETCH_COMPANY}${Object.entries(params).reduce(
          (acc, key, index) => {
            if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
            return `${acc}&${key[0]}=${key[1]}`;
          },
          '',
        )}`,
        method: 'GET',
      }),
    }),
    createCompany: builder.mutation<Company, ICreateCompany>({
      query: ({ ...company }) => ({
        url: `${FETCH_COMPANY}`,
        method: 'POST',
        body: company,
      }),
    }),
    updateCompany: builder.mutation<Company, IUpdateCompany>({
      query: ({ companyUUID, company }) => ({
        url: `${FETCH_COMPANY}/${companyUUID}`,
        method: 'PUT',
        body: company,
      }),
    }),
    deactivateCompany: builder.mutation<Company, IDeactivateCompany>({
      query: ({ companyUUID, ...active }) => ({
        url: `${FETCH_COMPANY}/${companyUUID}`,
        method: 'PUT',
        body: active,
      }),
    }),
    fetchTopUpForCompany: builder.query<
      ICompanyCreditResponse,
      { companyUUID: string }
    >({
      query: ({ companyUUID }) => ({
        url: `${FETCH_COMPANY}${TOP_UP_COMPANY}/${companyUUID}${CREDIT_REQUEST}`,
        method: 'GET',
      }),
    }),
    requestTopUpForCompany: builder.mutation<
      ICompanyCreditResponse,
      ICompanyTopUp
    >({
      query: ({ ...companyTopUpInfo }) => ({
        url: `${CORPORATE_CREDIT_REQUEST}`,
        method: 'POST',
        body: companyTopUpInfo,
      }),
    }),
    grantRequestTopUpForCompany: builder.mutation<
      ICompanyCreditResponse,
      ICompanyGrantTopUp
    >({
      query: ({ ...companyTopUpInfo }) => ({
        url: `${CORPORATE_ADD_CREDIT}`,
        method: 'POST',
        body: companyTopUpInfo,
      }),
    }),
  }),
});

export const {
  useFetchCompanyQuery,
  useFetchAllCompaniesQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useDeactivateCompanyMutation,
  useFetchTopUpForCompanyQuery,
  useRequestTopUpForCompanyMutation,
  useGrantRequestTopUpForCompanyMutation,
} = companyApi;
