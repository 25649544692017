/* eslint-disable prettier/prettier */
import { createAsyncThunk } from '@reduxjs/toolkit';
import requestRetry, { ResponseError } from '../../lib/request';
import {
  ITransactionQuery,
  ITransactionResponse,
  ITransactionField,
} from '../../types/transaction';
import * as routes from '../../constants/routes';
import { config } from '../../config';

const { REACT_APP_LEONCO_API_URL } = config;

export const fetchAllTransactions = createAsyncThunk(
  'transaction/allTransactions',
  async (params: ITransactionQuery, { rejectWithValue }) => {
    try {
      const queries = Object.entries(params).reduce((acc, key, index) => {
        if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
        return `${acc}&${key[0]}=${key[1]}`;
      }, '');

      const response = await requestRetry<ITransactionResponse>(
        `${REACT_APP_LEONCO_API_URL}${routes.TRANSACTIONS_FETCH}${queries}`,
        {
          method: 'GET',
        },
      );

      return response;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error as ResponseError);
    }
  },
);

export const fetchAllFilteredTransactions = createAsyncThunk(
  'transaction/allFilteredTransactions',
  async (params: ITransactionQuery, { rejectWithValue }) => {
    try {
      const queries = Object.entries(params).reduce((acc, key, index) => {
        if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
        return `${acc}&${key[0]}=${key[1]}`;
      }, '');

      const response = await requestRetry<ITransactionResponse>(
        `${REACT_APP_LEONCO_API_URL}${routes.TRANSACTIONS_FETCH}${queries}`,
        {
          method: 'GET',
        },
      );

      return response;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error as ResponseError);
    }
  },
);

export const fetchCorporateTransactions = createAsyncThunk(
  'transaction/corporateTransactions',
  async (
    { params, accountUUID }: { accountUUID: string; params: ITransactionQuery },
    { rejectWithValue },
  ) => {
    try {
      const queries = Object.entries(params).reduce((acc, key, index) => {
        if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
        return `${acc}&${key[0]}=${key[1]}`;
      }, '');

      const response = await requestRetry<ITransactionResponse>(
        `${REACT_APP_LEONCO_API_URL}${routes.TRANSACTIONS}/${accountUUID}${queries}`,
        {
          method: 'GET',
        },
      );

      return response;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error as ResponseError);
    }
  },
);

export const fetchFuelTransactions = createAsyncThunk(
  'transaction/fuelTransactions',
  async (params: ITransactionQuery, { rejectWithValue }) => {
    try {
      const queries = Object.entries(params).reduce((acc, key, index) => {
        if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
        return `${acc}&${key[0]}=${key[1]}`;
      }, '');

      const response = await requestRetry<ITransactionResponse>(
        `${REACT_APP_LEONCO_API_URL}${routes.TRANSACTIONS_FETCH}${queries}`,
        {
          method: 'GET',
        },
      );

      return response;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error as ResponseError);
    }
  },
);

export const fetchCustomerFilteredTransactions = createAsyncThunk(
  'transaction/customerFilteredTransactions',
  async (params: ITransactionQuery, { rejectWithValue }) => {
    try {
      const queries = Object.entries(params).reduce((acc, key, index) => {
        if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
        return `${acc}&${key[0]}=${key[1]}`;
      }, '');

      const response = await requestRetry<ITransactionResponse>(
        `${REACT_APP_LEONCO_API_URL}${routes.TRANSACTIONS_FETCH}${queries}`,
        {
          method: 'GET',
        },
      );

      return response;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error as ResponseError);
    }
  },
);

export const fetchStaffFilteredTransactions = createAsyncThunk(
  'transaction/staffFilteredTransactions',
  async (params: ITransactionQuery, { rejectWithValue }) => {
    try {
      const queries = Object.entries(params).reduce((acc, key, index) => {
        if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
        return `${acc}&${key[0]}=${key[1]}`;
      }, '');

      const response = await requestRetry<ITransactionResponse>(
        `${REACT_APP_LEONCO_API_URL}${routes.TRANSACTIONS_FETCH}${queries}`,
        {
          method: 'GET',
        },
      );

      return response;
    } catch (error) {
      console.error({ error });
      return rejectWithValue(error as ResponseError);
    }
  },
);
