import React, { Suspense, useEffect } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import './scss/style.scss';
import {
  LOGIN,
  PASSWORD_FORGOT,
  PASSWORD_RESET,
  PAGE404,
  PAGE500,
} from './constants/routes';
import { useAppDispatch, useAppSelector } from './lib/hooks/useRedux';
import { createFormErrors, ENGLISH } from './constants';
import { formTranslations } from './lib/hooks/translation';
import { setLanguage } from './state/global/reducer';
import { useTranslations } from './lib/hooks/translation/translations';

export const LoadingFallBack = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/auth/login'));
const PasswordForgot = React.lazy(() => import('./views/auth/password/forgot'));
const PasswordReset = React.lazy(() => import('./views/auth/password/reset'));
const Page404 = React.lazy(() => import('./views/404'));
const Page500 = React.lazy(() => import('./views/500'));

const App = () => {
  const { translation } = useTranslations();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);

  formTranslations.errors = createFormErrors(translation as any);

  useEffect(() => {
    dispatch(setLanguage(ENGLISH));

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, []);

  return (
    <HashRouter>
      <Suspense fallback={LoadingFallBack}>
        <Routes>
          <Route
            path="*"
            element={user?.id ? <DefaultLayout /> : <Login />}
          ></Route>

          <Route path={LOGIN} element={<Login />} />
          <Route path={PASSWORD_FORGOT} element={<PasswordForgot />} />
          <Route path={PASSWORD_RESET} element={<PasswordReset />} />
          <Route path={PAGE404} element={<Page404 />} />
          <Route path={PAGE500} element={<Page500 />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
};

export default App;
