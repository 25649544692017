import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ResponseError } from '../../lib/request';
import { Company, ICompanyCreditResponse } from '../../types/company';

export interface CompanyState {
  status: 'idle' | 'loading' | 'failed';

  apiError?: ResponseError;

  allCompanies?: Company[];
  corporateCompany?: Company;
  createdCompany?: Company;
  companyCreditRequests?: ICompanyCreditResponse;
}

const initialState: CompanyState = {
  status: 'idle',

  apiError: undefined,

  allCompanies: undefined,
  corporateCompany: undefined,
  createdCompany: undefined,
  companyCreditRequests: undefined,
};

export const companySlice = createSlice({
  name: 'companyDetails',
  initialState,
  reducers: {
    saveAllCompanies: (state, action: PayloadAction<Company[]>) => ({
      ...state,
      allCompanies: action.payload,
    }),
    saveCorporateCompany: (state, action: PayloadAction<Company>) => ({
      ...state,
      corporateCompany: action.payload,
    }),
    resetCorporateCompany: (state, _) => ({
      ...state,
      corporateCompany: undefined,
    }),
    resetCompanyDetails: (state, _) => ({
      ...state,
      allCompanies: undefined,
      corporateCompany: undefined,
      createdCompany: undefined,
      companyCreditRequests: undefined,
    }),
  },
});

export const {
  saveAllCompanies,
  saveCorporateCompany,
  resetCompanyDetails,
  resetCorporateCompany,
} = companySlice.actions;

export default companySlice.reducer;
