import { createApi } from '@reduxjs/toolkit/query/react';
import {
  FETCH_CORPORATE_STAFF,
  FETCH_LEONCO_STAFF,
  USER_ADD_CREDIT,
  USER_PROFILE,
} from '../../constants/routes';
import { IQuery } from '../../types/default';
import {
  IGetStaffParams,
  IStaffResponse,
  User,
  LeoncoUserRequest,
  CorporateUserRequest,
  IUpdateLeoncoStaff,
  IUpdateCorporateStaff,
  ISuspendStaff,
  IStaffGrantTopUp,
} from '../../types/user';
import { staggeredBaseQuery } from '../base-query';

export const userApi = createApi({
  reducerPath: 'staff',
  baseQuery: staggeredBaseQuery,
  endpoints: (builder) => ({
    fetchLeoncoStaff: builder.query<IStaffResponse, IQuery>({
      query: ({ ...params }) => ({
        url: `${FETCH_LEONCO_STAFF}${Object.entries(params).reduce(
          (acc, key, index) => {
            if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
            return `${acc}&${key[0]}=${key[1]}`;
          },
          '',
        )}`,
        method: 'GET',
      }),
    }),
    fetchCorporateStaff: builder.query<IStaffResponse, IGetStaffParams>({
      query: ({ companyUUID, params }) => ({
        url: `${FETCH_CORPORATE_STAFF}/${companyUUID}${Object.entries(
          params,
        ).reduce((acc, key, index) => {
          if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
          return `${acc}&${key[0]}=${key[1]}`;
        }, '')}`,
        method: 'GET',
      }),
    }),
    createLeoncoStaff: builder.mutation<User, LeoncoUserRequest>({
      query: ({ ...staff }) => ({
        url: `${FETCH_LEONCO_STAFF}`,
        method: 'POST',
        body: staff,
      }),
    }),
    createCorporateStaff: builder.mutation<User, CorporateUserRequest>({
      query: ({ ...staff }) => ({
        url: `${FETCH_CORPORATE_STAFF}`,
        method: 'POST',
        body: staff,
      }),
    }),
    updateLeoncoStaff: builder.mutation<User, IUpdateLeoncoStaff>({
      query: ({ userId, staff }) => ({
        url: `${USER_PROFILE}/${userId}`,
        method: 'PUT',
        body: staff,
      }),
    }),
    updateCorporateStaff: builder.mutation<User, IUpdateCorporateStaff>({
      query: ({ userId, staff }) => ({
        url: `${USER_PROFILE}/${userId}`,
        method: 'PUT',
        body: staff,
      }),
    }),
    suspendStaff: builder.mutation<User, ISuspendStaff>({
      query: ({ userId, ...active }) => ({
        url: `${USER_PROFILE}/${userId}`,
        method: 'PUT',
        body: active,
      }),
    }),
    grantRequestTopUpForStaff: builder.mutation<string, IStaffGrantTopUp>({
      query: ({ ...staffTopUpInfo }) => ({
        url: `${USER_ADD_CREDIT}`,
        method: 'POST',
        body: staffTopUpInfo,
      }),
    }),
  }),
});

export const {
  useFetchLeoncoStaffQuery,
  useFetchCorporateStaffQuery,
  useCreateLeoncoStaffMutation,
  useCreateCorporateStaffMutation,
  useUpdateLeoncoStaffMutation,
  useUpdateCorporateStaffMutation,
  useSuspendStaffMutation,
  useGrantRequestTopUpForStaffMutation,
} = userApi;
