import { createApi } from '@reduxjs/toolkit/query/react';
import {
  FETCH_CORPORATE_TRANSACTIONS,
  FETCH_TRANSACTIONS,
} from '../../constants/routes';
import {
  IGetTransactions,
  ITransactionQuery,
  ITransactionResponse,
} from '../../types/transaction';
import isEmpty from 'lodash/isEmpty';
import { staggeredBaseQuery } from '../base-query';

export const transactionsApi = createApi({
  reducerPath: 'transactions',
  baseQuery: staggeredBaseQuery,
  endpoints: (builder) => ({
    fetchAllTransactions: builder.query<
      ITransactionResponse,
      ITransactionQuery
    >({
      query: ({ ...queries }) => ({
        url: `${FETCH_TRANSACTIONS}${Object.entries(queries).reduce(
          (acc, key, index) => {
            if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
            return !isEmpty(key?.[1]) ? `${acc}&${key[0]}=${key[1]}` : '';
          },
          '',
        )}`,
        method: 'GET',
      }),
    }),
    fetchCorporateTransactions: builder.query<
      ITransactionResponse,
      IGetTransactions
    >({
      query: ({ companyUUID, ...params }) => ({
        url: `${FETCH_CORPORATE_TRANSACTIONS}${companyUUID && '/' + companyUUID}${Object.entries(
          params,
        ).reduce((acc, key, index) => {
          if (index === 0) return `${acc}?${key[0]}=${key[1]}`;
          return !isEmpty(key?.[1]) ? `${acc}&${key[0]}=${key[1]}` : '';
        }, '')}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useFetchAllTransactionsQuery,
  useFetchCorporateTransactionsQuery,
} = transactionsApi;
