import { createSlice } from '@reduxjs/toolkit';
import { ResponseError } from '../../lib/request';

export interface TokenState {
  status: 'idle' | 'loading' | 'failed';

  apiError?: ResponseError;
  loading: boolean;
  loggedIn: boolean;
  redirect?: boolean;

  accessToken?: string;
  refreshToken?: string;
}

const initialState: TokenState = {
  status: 'idle',

  apiError: undefined,
  loading: false,
  loggedIn: false,
  redirect: false,

  accessToken: undefined,
  refreshToken: undefined,
};

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    saveAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    saveRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    resetTokens: (state) => {
      state.accessToken = undefined;
      state.refreshToken = undefined;
      state.loggedIn = false;
    },
  },
});

export const { saveAccessToken, saveRefreshToken, resetTokens } =
  tokenSlice.actions;

export default tokenSlice.reducer;
