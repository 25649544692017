import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import localforage from 'localforage';
import reducer from './reducers';
import { currencyApi } from './currency/api';
import { authApi } from './auth/api';
import { companyApi } from './company/api';
import { inventoryApi } from './inventory/api';
import { transactionsApi } from './transaction/api';
import { userApi } from './user/api';
import { tokenApi } from './token/api';
import { stationApi } from './station/api';

const persistConfig = {
  key: 'root',
  version: 1,
  storage: localforage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      tokenApi.middleware,
      currencyApi.middleware,
      authApi.middleware,
      companyApi.middleware,
      inventoryApi.middleware,
      transactionsApi.middleware,
      userApi.middleware,
      stationApi.middleware,
    );
  },
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;
