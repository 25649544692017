export interface CoreUIState {
  sidebarShow: boolean;
  sidebarUnfoldable: boolean;
  asideShow: boolean;
  theme: boolean;
}

const initialState: CoreUIState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  asideShow: false,
  theme: false,
};

type args = { type?: string; [key: string]: any };

const changeState = (state = initialState, { type, ...rest }: args) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest };
    default:
      return state;
  }
};

export default changeState;
