import { ILogon, ILogonResponse, PhoneNumber, User } from '../types/user';

export enum ROLE {
  PROVIDER_ADMIN = 'PROVIDER_ADMIN',
  PROVIDER_STAFF = 'PROVIDER_STAFF',
  PROVIDER_SUDO = 'SUDO',
  CORPORATE_ADMIN = 'CORPORATE_ADMIN',
  CORPORATE_STAFF = 'CORPORATE_STAFF',
}

export interface IUserPrivilege {
  userRole: ROLE | string;
  isLeoncoPriviledgedUser: boolean;
  isClientAdminOrOwner: boolean;
  leoncoAdminView?: boolean;
}

export type IUser = User;

export type ILogonResponseDetails = ILogonResponse;

export type ILogonDetails = ILogon;

export interface IPerson {
  uuid: string;
  fullName: string;
  role: ROLE | string;
  companyUUID?: string;
  companyName?: string;
  phoneNumber: PhoneNumber;
}
