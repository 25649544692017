import { IUser, ROLE } from '../constants/user';
import { USER_PERMISSION } from '../constants/permissions';

export const defaultUserState: IUser = {
  id: '',
  uuid: '',
  fullName: '',
  phoneNumber: {
    countryCode: '',
    number: '',
    countryCodeName: '',
  },
  email: '',
  company: {
    joinedAt: '',
    name: '',
    uuid: '',
  },
  fuelStation: undefined,
  role: ROLE.CORPORATE_STAFF,
  cards: [],
  wallet: {
    balance: '',
    currency: '',
    updatedAt: undefined,
  },
  devices: [],
  autoCreatedUser: false,
  active: false,
  createdAt: '',
  updatedAt: '',
  settings: undefined,
};

export function leoncoTextChange(
  leoncoAdminView: boolean,
  translation: (key: string, config?: any) => string,
): string {
  if (leoncoAdminView) return translation('sidebar.leoncoStaff');

  return translation('sidebar.staff');
}

export function userRoleTranslated(
  userRole: ROLE | string,
  translation: (key: string, config?: any) => string,
): string {
  if (userRole === ROLE.PROVIDER_SUDO)
    return translation('global.userRoles.sudo');

  if (userRole === ROLE.PROVIDER_ADMIN)
    return translation('global.userRoles.providerAdmin');

  if (userRole === ROLE.PROVIDER_STAFF)
    return translation('global.userRoles.providerStaff');

  if (userRole === ROLE.CORPORATE_ADMIN)
    return translation('global.userRoles.corporateAdmin');
  if (userRole === ROLE.CORPORATE_STAFF)
    return translation('global.userRoles.corporateStaff');

  return '-';
}

export function leoncoPrivilegedUsers(userRole?: ROLE | string): boolean {
  if (userRole === ROLE.PROVIDER_ADMIN || userRole === ROLE.PROVIDER_SUDO)
    return true;

  return false;
}

export function isPermittedUser(userRole?: ROLE | string): boolean {
  if (
    userRole === ROLE.PROVIDER_ADMIN ||
    userRole === ROLE.PROVIDER_SUDO ||
    userRole === ROLE.CORPORATE_ADMIN
  )
    return true;

  return false;
}

export function isUserPermitted(userRole: number): boolean {
  const permittedUsers = [
    USER_PERMISSION.PROVIDER_ADMIN,
    USER_PERMISSION.CORPORATE_ADMIN,
    USER_PERMISSION.PROVIDER_SUDO,
  ];

  return permittedUsers.includes(userRole);
}
