import { createSlice } from '@reduxjs/toolkit';
import { ResponseError } from '../../lib/request';
import {
  IFuelStationResponse,
  IInventoryResponse,
} from '../../types/inventory';

export interface InventoryState {
  status: 'idle' | 'loading' | 'failed';

  apiError?: ResponseError;
  loading: boolean;

  allInventory?: IInventoryResponse;
  corporateInventory?: IInventoryResponse;

  allFuelStations?: IFuelStationResponse;
}

const initialState: InventoryState = {
  status: 'idle',

  apiError: undefined,
  loading: false,

  allInventory: undefined,
  corporateInventory: undefined,

  allFuelStations: undefined,
};

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    saveStations: (state, action) => {
      state.allFuelStations = action.payload;
    },
    resetInventoryDetails: (state, action) => {
      state.allInventory = undefined;
      state.corporateInventory = undefined;
      state.allFuelStations = undefined;
    },
  },
});

export const { resetInventoryDetails, saveStations } = inventorySlice.actions;

export default inventorySlice.reducer;
