import { TranslatedPair } from '../lib/hooks/translation/translations';
import { leoncoTextChange } from '../utils/user';

//******************** API ROUTES *****************************/
export const ALL_CURRENCIES = '/currency/all';
export const GET_CURRENCY = '/currency?base=';

export const LOGIN_USER = '/auth/login';
export const REFRESH_TOKEN = '/auth/refresh-token';
export const LOGOUT_USER = '/auth/logout';

export const FETCH_COMPANY = '/company';
export const TOP_UP_COMPANY = '/wallet/corporate';
export const CREDIT_REQUEST = '/credit-request';
export const CORPORATE_CREDIT_REQUEST = '/wallet/corporate/credit-request';
export const CORPORATE_ADD_CREDIT = '/wallet/corporate/add-credit';

export const FETCH_INVENTORY = '/inventory';
export const FETCH_CORPORATE_INVENTORY = '/inventory/corporate';

export const FETCH_FUEL_STATIONS = '/inventory/fuel-stations';
export const UPDATE_FUEL_STATIONS = '/inventory/update-fuel-station';
export const DELETE_FUEL_STATIONS = '/inventory/delete-fuel-station';

export const ALL_TRANSACTIONS = '/transactions';
export const FETCH_TRANSACTIONS = '/transactions/leonco';
export const FETCH_CORPORATE_TRANSACTIONS = '/transactions/corporate-company';

export const FETCH_LEONCO_STAFF = '/users/leonco';
export const FETCH_CORPORATE_STAFF = '/users/corporate';
export const ASSIGN_CORPORATE_CARD = '/inventory/card/staff';
export const USER_PROFILE = '/users/profile';
export const USER_ADD_CREDIT = '/wallet/staff/add-credit';

//******************** LOCAL ROUTES *****************************/
export const HOME = '/';
export const PAGE404 = '/404';
export const PAGE500 = '/500';
export const LOGIN = '/auth/login';
export const PASSWORD_FORGOT = '/auth/password/forgot';
export const PASSWORD_RESET = '/auth/password/reset';
export const DASHBOARD = '/dashboard';

//********************* RESOURCES ROUTES ********************************/
export const STAFF = '/staff';
export const CUSTOMERS = '/customers';
export const FUEL_STATIONS = '/stations';
export const COMPANY = '/company';

//********************* REPORTS ROUTES ********************************/
export const TRANSACTIONS = '/transactions';
export const TRANSACTIONS_FETCH = '/transactions/leonco';

//*********************** ASSET ROUTES ********************************/
export const INVENTORY = '/inventory';

//********************* ADMIN ROUTES ********************************/
export const ADMIN = '/admin';
export const SETTINGS = '/settings';
export const COMPANIES = '/';

// ********************** GLOBAL ROUTE NAMES **************************/
export const ROUTE_NAMES = {
  HOME: 'HOME',
  DASHBOARD: 'DASHBOARD',
  STAFF: 'STAFF',
  COMPANY: 'COMPANY',
  TRANSACTIONS: 'TRANSACTIONS',
  SETTINGS: 'SETTINGS',
  ADMIN: 'ADMIN',
  COMPANIES: 'COMPANIES',
  CUSTOMERS: 'CUSTOMERS',
  FUEL_STATIONS: 'FUEL STATIONS',
  INVENTORY: 'INVENTORY',

  // TITLES
  RESOURCES: 'RESOURCES',
  REPORTS: 'REPORTS',
  ASSETS: 'ASSETS',
};

// -------------------- TRANSLATE ROUTE NAMES ------------------------/
export const createRouteNames = (
  leoncoAdminView: boolean,
  translation: (key: string, config?: any) => string,
): TranslatedPair => {
  return {
    [ROUTE_NAMES.HOME]: translation('sidebar.home'),
    [ROUTE_NAMES.DASHBOARD]: translation('sidebar.dashboard'),

    [ROUTE_NAMES.RESOURCES]: translation('sidebar.resources'),
    [ROUTE_NAMES.STAFF]: leoncoTextChange(leoncoAdminView, translation),
    [ROUTE_NAMES.CUSTOMERS]: translation('sidebar.companies'),
    [ROUTE_NAMES.FUEL_STATIONS]: translation('sidebar.stations'),
    [ROUTE_NAMES.COMPANY]: translation('sidebar.company'),

    [ROUTE_NAMES.ASSETS]: translation('sidebar.assets'),
    [ROUTE_NAMES.INVENTORY]: translation('sidebar.inventory'),

    [ROUTE_NAMES.REPORTS]: translation('sidebar.reports'),
    [ROUTE_NAMES.TRANSACTIONS]: translation('sidebar.transactions'),

    [ROUTE_NAMES.SETTINGS]: translation('sidebar.settings'),
    [ROUTE_NAMES.ADMIN]: translation('sidebar.admin'),
    [ROUTE_NAMES.COMPANIES]: translation('sidebar.companies'),
  };
};
