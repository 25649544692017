import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ResponseError } from '../../lib/request';
import { IUser } from '../../constants/user';
import { IStaffResponse } from '../../types/user';

export interface UserState {
  status: 'idle' | 'loading' | 'failed';

  apiError?: ResponseError;
  loading: boolean;

  details?: IUser;

  leoncoStaff?: IStaffResponse;
  corporateStaff?: IStaffResponse;
}

const initialState: UserState = {
  status: 'idle',

  apiError: undefined,
  loading: false,

  details: undefined,

  leoncoStaff: undefined,
  corporateStaff: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetStaffDetails: (state, action) => {
      state.details = action.payload;
      state.corporateStaff = undefined;
      state.leoncoStaff = undefined;
    },
    saveCorporateStaff: (state, action: PayloadAction<IStaffResponse>) => ({
      ...state,
      corporateStaff: action.payload,
    }),
    saveLeoncoStaff: (state, action: PayloadAction<IStaffResponse>) => ({
      ...state,
      leoncoStaff: action.payload,
    }),
  },
});

export const { resetStaffDetails, saveCorporateStaff, saveLeoncoStaff } =
  userSlice.actions;

export default userSlice.reducer;
