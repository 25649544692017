import { createSlice } from '@reduxjs/toolkit';
import { ResponseError } from '../../lib/request';
import { ITransactionResponse } from '../../types/transaction';
import {
  fetchAllTransactions,
  fetchAllFilteredTransactions,
  fetchCorporateTransactions,
  fetchFuelTransactions,
  fetchCustomerFilteredTransactions,
  fetchStaffFilteredTransactions,
} from './actions';

export interface TransactionState {
  status: 'idle' | 'loading' | 'failed';

  apiError?: ResponseError;
  loading: boolean;

  allTransactions?: ITransactionResponse;
  corporateTransactions?: ITransactionResponse;
  staffTransactions?: ITransactionResponse;
  stationTransactions?: ITransactionResponse;

  customerFilteredTransactions?: ITransactionResponse;
  staffFilteredTransactions?: ITransactionResponse;
  fuelTransactions?: ITransactionResponse;
}

const initialState: TransactionState = {
  status: 'idle',

  apiError: undefined,
  loading: false,

  allTransactions: undefined,
  corporateTransactions: undefined,
  staffTransactions: undefined,
  stationTransactions: undefined,

  staffFilteredTransactions: undefined,
  customerFilteredTransactions: undefined,
  fuelTransactions: undefined,
};

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    saveAllTransactions: (state, action) => {
      state.allTransactions = undefined;
    },
    saveFuelTransactions: (state, action) => {
      state.fuelTransactions = undefined;
    },
    resetTransactionDetails: (state, action) => {
      state.allTransactions = undefined;
      state.corporateTransactions = undefined;
      state.staffTransactions = undefined;
      state.stationTransactions = undefined;
      state.staffFilteredTransactions = undefined;
      state.customerFilteredTransactions = undefined;
      state.fuelTransactions = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTransactions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllTransactions.fulfilled, (state, action) => {
        state.status = 'idle';
        state.allTransactions = action.payload;
        state.customerFilteredTransactions = action.payload;
        state.staffFilteredTransactions = action.payload;
      })
      .addCase(fetchAllFilteredTransactions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllFilteredTransactions.fulfilled, (state, action) => {
        state.status = 'idle';
        state.allTransactions = action.payload;
      })
      .addCase(fetchCorporateTransactions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCorporateTransactions.fulfilled, (state, action) => {
        state.status = 'idle';
        state.corporateTransactions = action.payload;
      })
      .addCase(fetchFuelTransactions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFuelTransactions.fulfilled, (state, action) => {
        state.status = 'idle';
        state.fuelTransactions = action.payload;
      })
      .addCase(fetchCustomerFilteredTransactions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCustomerFilteredTransactions.fulfilled, (state, action) => {
        state.status = 'idle';
        state.customerFilteredTransactions = action.payload;
      })
      .addCase(fetchStaffFilteredTransactions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStaffFilteredTransactions.fulfilled, (state, action) => {
        state.status = 'idle';
        state.staffFilteredTransactions = action.payload;
      });
  },
});

export const {
  resetTransactionDetails,
  saveAllTransactions,
  saveFuelTransactions,
} = transactionSlice.actions;

export default transactionSlice.reducer;
