export enum CURRENCY {
  AUD = 'AUD',
  CVE = 'CVE',
  CNY = 'CNY',
  EUR = 'EUR',
  GMD = 'GMD',
  GHS = 'GHS',
  GNF = 'GNF',
  KES = 'KES',
  LRD = 'LRD',
  MRU = 'MRU',
  NGN = 'NGN',
  SLE = 'SLE',
  GBP = 'GBP',
  USD = 'USD',
  XOF = 'XOF',
}

export const DEFAULT_CURRENCY = CURRENCY.SLE as string | CURRENCY;

export const CURRENCY_SELECT = [
  { key: CURRENCY.SLE, label: CURRENCY.SLE, value: CURRENCY.SLE },
  { key: CURRENCY.GBP, label: CURRENCY.GBP, value: CURRENCY.GBP },
  { key: CURRENCY.USD, label: CURRENCY.USD, value: CURRENCY.USD },
  { key: CURRENCY.EUR, label: CURRENCY.EUR, value: CURRENCY.EUR },
  { key: CURRENCY.KES, label: CURRENCY.KES, value: CURRENCY.KES },
  { key: CURRENCY.AUD, label: CURRENCY.AUD, value: CURRENCY.AUD },
  { key: CURRENCY.CNY, label: CURRENCY.CNY, value: CURRENCY.CNY },
  { key: CURRENCY.CVE, label: CURRENCY.CVE, value: CURRENCY.CVE },
  { key: CURRENCY.GMD, label: CURRENCY.GMD, value: CURRENCY.GMD },
  { key: CURRENCY.GHS, label: CURRENCY.GHS, value: CURRENCY.GHS },
  { key: CURRENCY.GNF, label: CURRENCY.GNF, value: CURRENCY.GNF },
  { key: CURRENCY.LRD, label: CURRENCY.LRD, value: CURRENCY.LRD },
  { key: CURRENCY.MRU, label: CURRENCY.MRU, value: CURRENCY.MRU },
  { key: CURRENCY.NGN, label: CURRENCY.NGN, value: CURRENCY.NGN },
  { key: CURRENCY.XOF, label: CURRENCY.XOF, value: CURRENCY.XOF },
];

export interface ISelectedCurrency {
  currencySelected: CURRENCY | string;
}

export type ICurrency = {
  AUD: number;
  CNY: number;
  CVE: number;
  EUR: number;
  GBP: number;
  GHS: number;
  GMD: number;
  GNF: number;
  KES: number;
  LRD: number;
  MRU: number;
  NGN: number;
  SLE: number;
  USD: number;
  XOF: number;
  base: 'AUD';
  date: string;
  id: string;
};
