import { createApi } from '@reduxjs/toolkit/query/react';
import { ICurrency } from '../../constants/currencies';
import { ALL_CURRENCIES, GET_CURRENCY } from '../../constants/routes';
import { staggeredBaseQuery } from '../base-query';

export const currencyApi = createApi({
  reducerPath: 'currency',
  baseQuery: staggeredBaseQuery,
  endpoints: (builder) => ({
    getAllCurrencies: builder.query<ICurrency[], void>({
      query: () => `${ALL_CURRENCIES}`,
    }),
    getCurrency: builder.query<ICurrency, string>({
      query: (baseCurrency: string) => `${GET_CURRENCY}${baseCurrency}`,
    }),
  }),
});

export const { useGetAllCurrenciesQuery, useGetCurrencyQuery } = currencyApi;
