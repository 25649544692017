import { createSlice } from '@reduxjs/toolkit';
import { ResponseError } from '../../lib/request';
import { ILogonResponse, User } from '../../types/user';

export interface AuthState {
  status: 'idle' | 'loading' | 'failed';

  apiError?: ResponseError;
  loading: boolean;
  loggedIn: boolean;
  redirect?: boolean;

  userDetails?: ILogonResponse | ResponseError;

  user?: User;
}

const initialState: AuthState = {
  status: 'idle',

  apiError: undefined,
  loading: false,
  loggedIn: false,
  redirect: false,

  userDetails: undefined,

  user: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveUser: (state, action) => {
      state.user = action.payload;
    },
    logoutUser: (state) => {
      localStorage.removeItem('user');
      state.user = undefined;
      state.userDetails = undefined;
      state.loggedIn = false;
    },
  },
});

export const { saveUser, logoutUser } = authSlice.actions;

export default authSlice.reducer;
