import { config } from '../config';

export const errorCodeMap = {
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  NOT_FOUND: 'NOT_FOUND',
  BAD_REQUEST: 'BAD_REQUEST',
  PROTECTED_RESOURCE: 'PROTECTED_RESOURCE',
  FORBIDDEN_ACCESS_PRIVILEGE: 'FORBIDDEN_ACCESS_PRIVILEGE',
  UNAUTHORIZED_ACCESS: 'UNAUTHORIZED_ACCESS',
  USER_DOESNT_HAVE_DEVICE_TOKEN: 'USER_DOESNT_HAVE_DEVICE_TOKEN',
  WRONG_CREDENTIALS: 'WRONG_CREDENTIALS',
  PASSWORD_MIN_LENGTH: 'PASSWORD_MIN_LENGTH',
  STATION_ID_REQUIRED: 'STATION_ID_REQUIRED',
  INVALID_TOKEN: 'INVALID_TOKEN',
  TOKEN_REQUIRED: 'TOKEN_REQUIRED',
  REQUEST_DOESNT_EXIST: 'REQUEST_DOESNT_EXIST',
  REQUEST_ALREADY_GRANTED: 'REQUEST_ALREADY_GRANTED',
  INSUFFICIENT_BALANCE: 'INSUFFICIENT_BALANCE',
  COMPANY_EXISTS: 'COMPANY_EXISTS',
  INCORRECT_USER_PIN: 'INVALID_USER_PIN',
  DUPLICATE_TRANSACTION: 'DUPLICATE_TRANSACTION',

  DEVICE_TOKEN_REQUIRED: 'DEVICE_TOKEN_REQUIRED',
  CARD_ALREADY_ASSIGNED_TO_COMPANY: 'CARD_ALREADY_ASSIGNED_TO_COMPANY',

  EMAIL_DOESNT_EXIST: 'EMAIL_DOESNT_EXIST',
  COMPANY_DOESNT_EXIST: 'COMPANY_DOESNT_EXIST',
  USER_NOT_EMPLOYED_IN_COMPANY: 'USER_NOT_EMPLOYED_IN_COMPANY',
  USER_DOESNT_EXIST: "USER DOESN'T EXIST",

  AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
  USER_REVOKED: 'USER_REVOKED',
  WRONG_PASSWORD: 'WRONG_PASSWORD',
  USER_SUSPENDED: 'USER_SUSPENDED',
  USER_DEACTIVATED: 'USER_DEACTIVATED',
  PHONE_NUMBER_DOESNT_EXIST: 'PHONE_NUMBER_DOESNT_EXIST',

  PASSWORD_REQUIRED: 'PASSWORD_REQUIRED',
  EMAIL_REQUIRED: 'EMAIL_REQUIRED',
  PHONE_NUMBER_REQUIRED: 'PHONE_NUMBER_REQUIRED',
  COUNTRY_CODE_REQUIRED: 'COUNTRY_CODE_REQUIRED',
  COUNTRY_CODE_NAME_REQUIRED: 'COUNTRY_CODE_NAME_REQUIRED',
  FULL_NAME_REQUIRED: 'FULL_NAME_REQUIRED',
  CODE_REQUIRED: 'CODE_REQUIRED',

  PASSWORD_VALIDATION_ERROR: 'PASSWORD_VALIDATION_ERROR',
  PHONE_NUMBER_VALIDATION_ERROR: 'PHONE_NUMBER_VALIDATION_ERROR',
  EMAIL_VALIDATION_ERROR: 'EMAIL_VALIDATION_ERROR',
  INVALID_EMAIL: 'INVALID_EMAIL',
  FULL_NAME_VALIDATION_ERROR: 'FULL_NAME_VALIDATION_ERROR',

  FAILED_REGISTERING_USER: 'FAILED_REGISTERING_USER',

  VALIDATION_ERROR: 'VALIDATION_ERROR',

  EMAIL_ALREADY_EXISTS: 'EMAIL_ALREADY_EXISTS',
  MOBILE_ALREADY_EXISTS: 'MOBILE_ALREADY_EXISTS',
  INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',

  RESET_PASSWORD_CODE_MISMATCH: 'RESET_PASSWORD_CODE_MISMATCH',
  NO_PASSWORD_RESET_CODE: 'NO_PASSWORD_RESET_CODE',

  PHONE_NUMBER_MINIMUM_LENGTH: 'PHONE_NUMBER_MINIMUM_LENGTH',
  PHONE_NUMBER_MAXIMUM_LENGTH: 'PHONE_NUMBER_MAXIMUM_LENGTH',
  COUNTRY_CODE_MINIMUM_LENGTH: 'COUNTRY_CODE_MINIMUM_LENGTH',
  COUNTRY_CODE_MAXIMUM_LENGTH: 'COUNTRY_CODE_MAXIMUM_LENGTH',
  COUNTRY_CODE_NAME_MINIMUM_LENGTH: 'COUNTRY_CODE_NAME_MINIMUM_LENGTH',
  COUNTRY_CODE_NAME_MAXIMUM_LENGTH: 'COUNTRY_CODE_NAME_MAXIMUM_LENGTH',
  COUNTRY_CODE_NAME_INVALID: 'COUNTRY_CODE_NAME_INVALID',

  REFRESH_TOKEN_VALIDATION_ERROR: 'REFRESH_TOKEN_VALIDATION_ERROR',
  TOKEN_TIMEOUT: 'TOKEN_TIMEOUT',
  REFRESH_TOKEN_ERROR: 'REFRESH_TOKEN_ERROR',

  DEVICE_NAME_REQUIRED: 'DEVICE_NAME_REQUIRED',
  IDENTIFICATION_NUMBER_REQUIRED: 'IDENTIFICATION_NUMBER_REQUIRED',
  IDENTIFICATION_TYPE_REQUIRED: 'IDENTIFICATION_TYPE_REQUIRED',
  INVENTORY_ALREADY_EXISTS: 'INVENTORY_ALREADY_EXISTS',
  COMPANY_UUID_REQUIRED: 'COMPANY_UUID_REQUIRED',
  INVENTORY_NOT_FOUND: 'INVENTORY_NOT_FOUND',
  STATION_NOT_FOUND: 'STATION_NOT_FOUND',
  CARD_ALREADY_ASSIGNED_TO_USER: 'CARD_ALREADY_ASSIGNED_TO_USER',
  INVENTORY_UUID_REQUIRED: 'INVENTORY_UUID_REQUIRED',
  STAFF_REQUIRED: 'STAFF_REQUIRED',
  INSUFFICIENT_CREDIT: 'INSUFFICIENT_CREDIT',
};

export interface FormError {
  errorAria: string;
  errorText: string;
}

export interface FormErrors {
  [key: string]: FormError;
}

export const createFormErrors = (
  translation: (key: string, config?: any) => string,
): FormErrors => {
  return {
    // ---------------- GLOBAL ---------------------- //
    [errorCodeMap.NOT_FOUND]: {
      errorAria: translation(`global.formValidation.notFound.aria`),
      errorText: translation(`global.formValidation.notFound.text`),
    },
    [errorCodeMap.BAD_REQUEST]: {
      errorAria: translation(`global.formValidation.badRequest.aria`),
      errorText: translation(`global.formValidation.badRequest.text`),
    },
    [errorCodeMap.INTERNAL_SERVER_ERROR]: {
      errorAria: translation(`global.formValidation.internalServerError.aria`),
      errorText: translation(`global.formValidation.internalServerError.text`),
    },

    // ---------------- LOGON FORM ---------------------- //

    [errorCodeMap.EMAIL_DOESNT_EXIST]: {
      errorAria: translation('logon.formValidation.email.invalid.aria'),
      errorText: translation('logon.formValidation.email.invalid.text'),
    },
    [errorCodeMap.PHONE_NUMBER_DOESNT_EXIST]: {
      errorAria: translation('logon.formValidation.phoneNumber.exists.aria'),
      errorText: translation('logon.formValidation.phoneNumber.exists.text'),
    },
    [errorCodeMap.WRONG_PASSWORD]: {
      errorAria: translation('logon.formValidation.password.invalid.aria'),
      errorText: translation('logon.formValidation.password.invalid.text'),
    },
    [errorCodeMap.PASSWORD_VALIDATION_ERROR]: {
      errorAria: translation('logon.formValidation.password.minLength.aria'),
      errorText: translation('logon.formValidation.password.minLength.text'),
    },

    // ---------------- REGISTER FORM ---------------------- //

    [errorCodeMap.PHONE_NUMBER_VALIDATION_ERROR]: {
      errorAria: translation(
        'register.formValidation.phoneNumber.invalid.aria',
      ),
      errorText: translation(
        'register.formValidation.phoneNumber.invalid.text',
      ),
    },
    [errorCodeMap.FULL_NAME_VALIDATION_ERROR]: {
      errorAria: translation('register.formValidation.fullName.minLength.aria'),
      errorText: translation('register.formValidation.fullName.minLength.text'),
    },
    [errorCodeMap.PASSWORD_VALIDATION_ERROR]: {
      errorAria: translation('register.formValidation.password.minLength.aria'),
      errorText: translation('register.formValidation.password.minLength.text'),
    },
    [errorCodeMap.EMAIL_VALIDATION_ERROR]: {
      errorAria: translation('register.formValidation.email.invalid.aria'),
      errorText: translation('register.formValidation.email.invalid.text'),
    },
    [errorCodeMap.EMAIL_ALREADY_EXISTS]: {
      errorAria: translation('register.formValidation.email.exists.aria'),
      errorText: translation('register.formValidation.email.exists.text'),
    },
    [errorCodeMap.MOBILE_ALREADY_EXISTS]: {
      errorAria: translation('register.formValidation.phoneNumber.exists.aria'),
      errorText: translation('register.formValidation.phoneNumber.exists.text'),
    },

    // ________________ ADD USER FORM _____________________ //
    [errorCodeMap.EMAIL_REQUIRED]: {
      errorAria: translation('logon.formValidation.email.empty.text'),
      errorText: translation('logon.formValidation.email.empty.text'),
    },
    [errorCodeMap.PASSWORD_REQUIRED]: {
      errorAria: translation('register.formValidation.password.empty.text'),
      errorText: translation('register.formValidation.password.empty.text'),
    },
    [errorCodeMap.PHONE_NUMBER_REQUIRED]: {
      errorAria: translation('register.formValidation.phoneNumber.empty.aria'),
      errorText: translation('register.formValidation.phoneNumber.empty.text'),
    },
    [errorCodeMap.PHONE_NUMBER_MAXIMUM_LENGTH]: {
      errorAria: translation(
        'register.formValidation.phoneNumber.maxLength.aria',
      ),
      errorText: translation(
        'register.formValidation.phoneNumber.maxLength.text',
      ),
    },
    [errorCodeMap.PHONE_NUMBER_MINIMUM_LENGTH]: {
      errorAria: translation(
        'register.formValidation.phoneNumber.minLength.aria',
      ),
      errorText: translation(
        'register.formValidation.phoneNumber.minLength.text',
      ),
    },
    [errorCodeMap.COUNTRY_CODE_REQUIRED]: {
      errorAria: translation('register.formValidation.countryCode.empty.aria'),
      errorText: translation('register.formValidation.countryCode.empty.text'),
    },
    [errorCodeMap.COUNTRY_CODE_MAXIMUM_LENGTH]: {
      errorAria: translation(
        'register.formValidation.countryCode.maxLength.aria',
      ),
      errorText: translation(
        'register.formValidation.countryCode.maxLength.text',
      ),
    },
    [errorCodeMap.COUNTRY_CODE_MINIMUM_LENGTH]: {
      errorAria: translation(
        'register.formValidation.countryCode.minLength.aria',
      ),
      errorText: translation(
        'register.formValidation.countryCode.minLength.text',
      ),
    },
    [errorCodeMap.COUNTRY_CODE_NAME_MAXIMUM_LENGTH]: {
      errorAria: translation(
        'register.formValidation.countryCodeName.maxLength.aria',
      ),
      errorText: translation(
        'register.formValidation.countryCodeName.maxLength.text',
      ),
    },
    [errorCodeMap.COUNTRY_CODE_NAME_MINIMUM_LENGTH]: {
      errorAria: translation(
        'register.formValidation.countryCodeName.minLength.aria',
      ),
      errorText: translation(
        'register.formValidation.countryCodeName.minLength.text',
      ),
    },
    [errorCodeMap.COUNTRY_CODE_NAME_REQUIRED]: {
      errorAria: translation(
        'register.formValidation.countryCodeName.empty.aria',
      ),
      errorText: translation(
        'register.formValidation.countryCodeName.empty.text',
      ),
    },
    [errorCodeMap.COUNTRY_CODE_NAME_INVALID]: {
      errorAria: translation(
        'register.formValidation.countryCodeName.empty.aria',
      ),
      errorText: translation(
        'register.formValidation.countryCodeName.empty.text',
      ),
    },

    // ---------------- PASSWORD FORM ---------------------- //
    [errorCodeMap.CODE_REQUIRED]: {
      errorAria: translation('password.formValidation.code.empty.aria'),
      errorText: translation('password.formValidation.code.empty.text'),
    },
    [errorCodeMap.RESET_PASSWORD_CODE_MISMATCH]: {
      errorAria: translation('password.formValidation.code.invalid.aria'),
      errorText: translation('password.formValidation.code.invalid.text'),
    },
    [errorCodeMap.NO_PASSWORD_RESET_CODE]: {
      errorAria: translation('password.formValidation.code.noCode.aria'),
      errorText: translation('password.formValidation.code.noCode.text'),
    },
  };
};

export const minimumPasswordCharactersLength = 6;
export const minimumFullNameCharactersLength = 2;

export const sensitiveprimaryCodesMap = {
  TOKENS: 'TOKENS',
  USER_ID: 'USER_ID',
  LANGUAGE: 'LANGUAGE',
};

export const sensitiveprimaryDefaultOptions = {
  sharedPreferencesName: 'mySharedPrefs',
  keychainService: 'myKeychain',
};

// ---------------- ACCOUNT TERMINATION PERIOD LIMIT ---------------------- //
export const EMAIL_VERIFICATION_MAX_DAYS = 14;
export const EMAIL_VERIFICATION_FIRST_WARNING = 10;
export const EMAIL_VERIFICATION_SECOND_WARNING = 6;
export const EMAIL_VERIFICATION_FINAL_WARNING = 3;

// ----------------- LANGUAGES ------------------------------------------- //
export const ENGLISH = 'en';

export enum SUPPORTED_LANGUAGES {
  ENGLISH = 'en',
}

// ---------------- HTTP METHODS ----------------------------------------- //
export const httpMethod = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE',
};
