import retry from 'async-retry';
import merge from 'lodash/merge';

import store from '../../state/store';

import {
  IResponseValidationInnerError,
  IError,
  IResponseError,
} from '../../types/errors';

import { config } from '../../config';
import { DEFAULT_CURRENCY } from '../../constants/currencies';
import { ENGLISH } from '../../constants';

const { REACT_APP_TOKEN_API_ID } = config;

export class ResponseError extends Error {
  public message = '';

  public code?: string;

  public errorMessage?: string;

  public status?: number;

  public errorCode?: string;

  public error?: IError | IResponseValidationInnerError[];

  constructor(response: IResponseError) {
    super(response.message || response.errorMessage);

    Object.assign(this, response);
  }
}

const defaultOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'x-authentication-context': 'admin-web',
    'Access-Control-Allow-Origin': '*',
    'x-api-token': REACT_APP_TOKEN_API_ID,
    'Content-Language': 'en',
    'Content-Currency': DEFAULT_CURRENCY,
    Authorization: REACT_APP_TOKEN_API_ID,
  },
  method: 'GET',
};

const isJson = (response: Response): boolean => {
  const contentType = response.headers.get('Content-Type');
  return !!(contentType && contentType.indexOf('application/json') !== -1);
};

async function request<T = { [key: string]: any }>(
  url: string,
  options: RequestInit = {},
  fetchFn: typeof fetch = fetch,
): Promise<T> {
  try {
    const state = store.getState();

    const token = state.tokenReducer.accessToken || REACT_APP_TOKEN_API_ID;

    const language = `${state?.global?.language?.languageTag}` || ENGLISH;

    const currency = state?.global?.selectedCurrency?.base || DEFAULT_CURRENCY;

    const headersToUpdate = {
      'Content-Language': language,
      'Content-Currency': currency,
      Authorization: `Bearer ${token}`,
      'x-api-token': `Bearer ${token}`,
    };

    // Merge updated headers with default headers
    const updatedOptions = merge({}, defaultOptions, options, {
      headers: headersToUpdate,
    });

    const response = await fetchFn(url, updatedOptions);

    const json = isJson(response) ? await response.json() : null;

    if (response.ok) return json;

    throw new ResponseError(json);
  } catch (error: any) {
    console.error(error);
    throw new ResponseError(error);
  }
}

async function requestRetry<T = { [key: string]: any }>(
  url: string,
  options: RequestInit = {},
  fetchFn: typeof fetch = fetch,
): Promise<T> {
  const response = await retry(
    async (bail: any) => {
      try {
        return await request<T>(url, options, fetchFn);
      } catch (e: any) {
        if (e.status === 401) {
        }

        if (e.status >= 400 || e.status < 500) return bail(e as IResponseError);

        throw e;
      }
    },
    {
      retries: 5,
    },
  );

  return response;
}

export default requestRetry;
