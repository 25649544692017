import { combineReducers } from '@reduxjs/toolkit';

import tokenReducer from './token/reducer';
import global from './global/reducer';
import coreui from './coreui/reducer';
import auth from './auth/reducer';
import user from './user/reducer';
import companyDetails from './company/reducer';
import transaction from './transaction/reducer';
import inventory from './inventory/reducer';
import { tokenApi } from './token/api';
import { currencyApi } from './currency/api';
import { authApi } from './auth/api';
import { companyApi } from './company/api';
import { inventoryApi } from './inventory/api';
import { transactionsApi } from './transaction/api';
import { userApi } from './user/api';
import { stationApi } from './station/api';

const rootReducer = combineReducers({
  tokenReducer,
  global,
  coreui,
  auth,
  user,
  companyDetails,
  transaction,
  inventory,
  [tokenApi.reducerPath]: tokenApi.reducer,
  [currencyApi.reducerPath]: currencyApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [inventoryApi.reducerPath]: inventoryApi.reducer,
  [transactionsApi.reducerPath]: transactionsApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [stationApi.reducerPath]: stationApi.reducer,
});

export default rootReducer;
