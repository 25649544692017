// this file reads the environmental variables into the app
export interface IConfig {
  REACT_APP_DEV_MODE: boolean;
  REACT_APP_PRODUCTION_MODE: boolean;

  REACT_APP_COMPANY_NAME: string;
  REACT_APP_APPLICATION_NAME: string;

  REACT_APP_VERSION: string;
  REACT_APP_NODE_ENV: 'development' | 'production';

  REACT_APP_TOKEN_API_ID: string;
  REACT_APP_LEONCO_API_URL: string;

  REACT_APP_S3_BUCKET_REGION: string;
  REACT_APP_S3_BUCKET_NAME: string;
  REACT_APP_S3_URL: string;
  REACT_APP_AWS_ACCESS_KEY_ID: string;
  REACT_APP_AWS_SECRET_ACCESS_KEY: string;

  REACT_APP_GOOGLE_MAPS_KEY: string;
}

const REACT_APP_NODE_ENV = process.env.REACT_APP_NODE_ENV as
  | 'development'
  | 'production';

const config: IConfig = {
  REACT_APP_NODE_ENV,
  REACT_APP_DEV_MODE: REACT_APP_NODE_ENV?.toLowerCase() !== 'production',
  REACT_APP_PRODUCTION_MODE: REACT_APP_NODE_ENV?.toLowerCase() === 'production',

  REACT_APP_COMPANY_NAME: process.env.REACT_APP_COMPANY_NAME || 'Leonco',
  REACT_APP_APPLICATION_NAME:
    process.env.REACT_APP_APPLICATION_NAME || 'Leonco Fuel Management System',

  REACT_APP_VERSION: process.env.REACT_APP_VERSION || '',

  REACT_APP_TOKEN_API_ID: process.env.REACT_APP_TOKEN_API_ID || '',

  REACT_APP_LEONCO_API_URL: process.env.REACT_APP_LEONCO_API_URL || '',

  REACT_APP_S3_BUCKET_REGION: process.env.REACT_APP_S3_BUCKET_REGION || '',
  REACT_APP_S3_BUCKET_NAME: process.env.REACT_APP_S3_BUCKET_NAME || '',
  REACT_APP_S3_URL: process.env.REACT_APP_S3_URL || '',
  REACT_APP_AWS_ACCESS_KEY_ID: process.env.REACT_APP_AWS_ACCESS_KEY_ID || '',
  REACT_APP_AWS_SECRET_ACCESS_KEY:
    process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || '',

  REACT_APP_GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY || '',
};

export { config };
